<template>
  <div>
    <v-card flat elevation="0" class="">
      <v-toolbar dense elevation="0">
        <div style="max-width: 100px">
          <v-select :items="itemsPerPageOption" label="Show Items" dense v-model="datatable_options.itemsPerPage" style="max-width: 100px" hide-details single-line></v-select>
        </div>
        <div style="max-width: 100px" class="ml-1">
          <v-btn color="error" small :disabled="selectedRow.length == 0" @click="deleteMultipleData()"><v-icon>mdi-delete</v-icon></v-btn>
        </div>
        <div style="max-width: 100px" class="ml-1">
          <v-btn small :disabled="selectedRow.length == 0" @click="selectedRow = []"><v-icon>mdi-playlist-minus</v-icon></v-btn>
        </div>

        <v-spacer></v-spacer>

        <v-text-field v-if="!isMobile" dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

        <v-btn icon @click="showFilter()" title="Filter">
          <v-icon>mdi-filter</v-icon>
        </v-btn>
        <v-btn icon @click="getData(true)" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="px-2" v-if="isMobile">
        <v-text-field class="mb-2" dense clearable autofocus outlined placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 3000px" hide-details single-line></v-text-field>
      </div>

      <v-data-table :options.sync="datatable_options" :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :hide-default-header="isMobile" :server-items-length="datatable.totalRows" :items-per-page="datatable_options.itemsPerPage" dense class="mb-3 my-0" hide-default-footer @page-count="pageCount = $event" multi-sort>
        <template v-slot:[`item.id`]="{ item }">
          <v-checkbox :value="item.id" multiple v-model="selectedRow" hide-details class="my-0 py-0" dense></v-checkbox>
        </template>
        <template v-slot:[`item.subject`]="{ item }">
          <span class="d-inline-block text-truncate" @click="showDialogBody(item)" style="color: red;cursor: pointer;max-width: 200px;">{{ item.subject }}</span>
        </template>
        <template v-slot:[`item.body`]="{ item }">
          <v-icon @click="showDialogBody(item)">mdi-eye</v-icon>
        </template>
        <template v-slot:[`item.sent_status`]="{ item }">
          <v-chip small dark color="red" v-if="item.sent_status == 'cancel'">{{ item.sent_status }}</v-chip>
          <v-chip small dark color="green" v-if="item.sent_status == 'delivered'">{{ item.sent_status }}</v-chip>
          <v-chip small dark color="grey" v-if="item.sent_status != 'delivered' && item.sent_status != 'cancel'">{{ item.sent_status }}</v-chip>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no results.</v-alert>
      </v-data-table>
      <div class="text-right pt-2 mx-auto" style="max-width: 600px">
        <v-pagination v-model="datatable_options.page" :length="totalPage"></v-pagination>
      </div>
    </v-card>

    <v-dialog v-model="dialogFilter" max-width="600">
      <v-card>
        <v-toolbar class="text-h5 grey lighten-2" elevation="0">
          <v-toolbar-title>Filter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text icon @click="closeFilter();">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="py-2" style="min-height: 500px; max-height: 800px;overflow-y: auto;">
          <v-autocomplete v-model="filterForm.sent_status" label="Sent Status" :items="filterData.sent_status" class="mb-2" hide-details clear-icon clearable outlined item-text="label" item-value="value"></v-autocomplete>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" @click="runFilter()">Filter</v-btn>
          <v-btn @click="resetFilter()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="closeFilter();">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <app-dialog v-model="dialogDetail" :title="dialogDetailData.subject">
      <template #toolbar>
        <app-button title="Send Email" mdi-icon="mdi-send"></app-button>
        <app-button title="Delete" mdi-icon="mdi-delete" @click="deleteData(dialogDetailData.id)" :loading="loading_delete"></app-button>
        <app-button title="Disable" mdi-icon="mdi-cancel" @click="cancelData(dialogDetailData.id)" :loading="loading_cancel"></app-button>
      </template>
      <v-row>
        <v-col lg="3">
          <card-expansion title="General">
            <app-data-line block label="Subject" v-model="dialogDetailData.subject"></app-data-line>
            <app-data-line block label="To" v-model="dialogDetailData.to"></app-data-line>
            <app-data-line block label="CC" v-model="dialogDetailData.cc"></app-data-line>
            <app-data-line block label="BCC" v-model="dialogDetailData.bcc"></app-data-line>
            <app-data-line block label="Created Date" v-model="dialogDetailData.created_date"></app-data-line>
            <app-data-line block label="Created By" v-model="dialogDetailData.created_by"></app-data-line>
            <app-data-line block label="Sent Status">
              <v-chip small dark color="red" v-if="dialogDetailData.sent_status == 'cancel'">{{ dialogDetailData.sent_status }}</v-chip>
              <v-chip small dark color="green" v-if="dialogDetailData.sent_status == 'delivered'">{{ dialogDetailData.sent_status }}</v-chip>
              <v-chip small dark color="grey" v-if="dialogDetailData.sent_status != 'delivered' && dialogDetailData.sent_status != 'cancel'">{{ dialogDetailData.sent_status }}</v-chip>
            </app-data-line>
            <app-data-line block label="Send Date" v-model="dialogDetailData.send_date"></app-data-line>
          </card-expansion>
        </v-col>
        <v-col>
          <card-expansion title="Mail Body">
            <v-sheet color="transparent">
              <div v-html="dialogDetailData.body"></div>
            </v-sheet>
          </card-expansion>
        </v-col>
      </v-row>
    </app-dialog>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton.vue';
import DetailDialog from "./DetailDialog.vue";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { DetailDialog, AppButton },
  props: {
    sent_status: {
      type: Boolean,
      default: null,
    }
  },
  data() {
    return {
      loading: null,
      loading_delete: null,
      search: "",
      datatable_options: {
        itemsPerPage: 20
      },
      itemsPerPageOption: [20, 50, 100, 500],
      totalPage: 0,
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "#", value: "id" },
          { text: "subject", value: "subject" },
          { text: "To", value: "to" },
          { text: "Body", value: "body" },
          { text: "Sent Status", value: "sent_status", cellClass: "text-center" },
          { text: "Send Date", value: "send_date" },
          { text: "Created By", value: "created_by" },
          { text: "Created Date", value: "created_date" },
        ],
        data: [],
      },

      dialogFilter: false,
      filterData: {
        sent_status: [
          { label: "New", value: 'new' },
          { label: "Delivered", value: 'delivered' },
          { label: "Failed", value: 'failed' },
          { label: "Cancel", value: 'cancel' }
        ],
      },
      filterForm: {
        sent_status: null,
      },

      dialogDetail: null,
      dialogDetailData: {},

      selectedRow: [],

      loading_cancel: false,
    };
  },

  watch: {
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.datatable_options.page = 1;
        this.getData();
      },
      deep: true,
    },
  },

  computed: {
    filterStatusColorOpen: () => {
      if (this.inArray('open', this.filterForm.status)) return 'primary';

      return '';
    }
  },

  methods: {
    refreshData() {
      this.getData();
    },
    getData(refresh) {
      const uri = "mailoutbox/data";
      if (refresh) this.AxiosStorageRemove("POST", uri);

      this.datatable.loading = true;

      var formData = this.dataTableFormData(this.datatable_options, this.search, this.filterForm);

      this.$axios
        .post(uri, formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
            this.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
    showFilter() {
      this.dialogFilter = true;
      this.filterData.area_code = this.$store.state.master.area;
    },
    closeFilter() {
      this.dialogFilter = false;
    },
    runFilter() {
      this.dialogFilter = false;

      this.datatable_options.page = 1;

      this.getData(true);
    },
    resetFilter() {
      this.filterForm = {
        status: [],
        substatus: [],
        area_code: [],
        department: [],
      }
      this.dialogFilter = false;
      this.getData(true);
    },
    filterByStatusToogle(status) {

      if (status == 'all') {
        this.filterForm.status = [];
      } else {
        if (this.inArray(status, this.filterForm.status)) {
          let i = null;
          this.filterForm.status.forEach((item) => {
            if (item == status) {
              i = this.filterForm.status.indexOf(item);
            }
          })

          if (i != null)
            this.filterForm.status.splice(i, 1);
        } else {
          this.filterForm.status.push(status);
        }
      }

      this.getData(true);
    },
    showDialogBody(data) {
      this.dialogDetailData = data
      this.dialogDetail = true;
    },
    closeDialogBody() {
      this.dialogDetailData = {}
      this.dialogDetail = false;
    },
    onRowClick(row) {
      this.showDialogBody(row)
    },
    deleteData(id) {
      let post = async () => {
        this.loading_delete = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", id);
        await this.$axios
          .post("mailoutbox/delete", formData)
          .then((res) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.closeDialogBody();
            this.refreshData();
          })
          .catch((error) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
            this.closeDialogBody();
            this.refreshData();
          });
        this.selectedRow = [];
      };

      this.showConfirm("Confirm", "Delete data?", post);
    },
    cancelData(id) {
      let post = async () => {
        this.loading_cancel = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", id);
        await this.$axios
          .post("mailoutbox/cancel", formData)
          .then((res) => {
            this.loading_cancel = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.closeDialogBody();
            this.refreshData();
          })
          .catch((error) => {
            this.loading_cancel = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
            this.closeDialogBody();
            this.refreshData();
          });
        this.selectedRow = [];
      };

      this.showConfirm("Confirm", "Cancel data?", post);
    },

    deleteMultipleData() {
      if (this.selectedRow.length == 0) {
        this.showAlert("error", "No Selected Row Found!");
      }
      let post = async () => {
        this.loading_delete = true;
        this.showLoadingOverlay(true);
        var formData = {
          selected_id: this.selectedRow
        }
        this.objecTo
        await this.$axios
          .post("mailoutbox/delete-multiple", this.objectToFormData(formData))
          .then((res) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);
            this.refreshData();
          })
          .catch((error) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
            this.refreshData();
          });
        this.selectedRow = [];
      };

      this.showConfirm("Confirm", "Delete data?", post);
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
